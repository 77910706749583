:root {
  --main-dark-color: black; 
  --main-mid-color: #292929;
  --main-light-color: white;
  --accent-gold-color: #D1B986;
  --accent-darker-color:#1C1C1C;
  --accent-dark-color: #363636;
  --accent-mid-color: #4A4A4A;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.4; 
  color: white; 
  color: var(--main-light-color);

  background: #292929;

  background: var(--main-mid-color);
}

.NavigationBar{
  z-index: 1000000;
}

a{
  
  color: white;
  
  color: var(--main-light-color);
}

.main-content{
  margin-left: 3%;
}

@media only screen and (max-width: 992px) {
  .main-content{
    margin-left: 0%;
  }
}

h1{
  letter-spacing: 0px;
  font-weight: 400;
  text-transform: uppercase;
}

h2{
  letter-spacing: 0px;
  font-weight: 300;
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.vertical-center-2 {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.vertical-center-3 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.background-container{
  width: 100%;
  height: 100%;

}

.text-gold{
  color:#D1B986;
  color:var(--accent-gold-color);
}

.row{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
  
}

.card-image{
  overflow: hidden;
}


.cbg-dark{
  background-color: black;
  background-color: var(--main-dark-color);

  background-clip: content-box;
}

#HomeLogoContainer{
  margin-left: 75%;
}

#HomeLogo{
  width: 100%;
  right: 0;
  top:0;
  float: right;
  margin: 2%;
  z-index: 121;
  display: inline-block;
}

@media only screen and (max-width: 992px) {
  #HomeLogoContainer{
    margin-right: 60%;
    margin-left: 0;
  }
  
  #HomeLogo{
    width: 100%;
    float: left;
    top:0;
    
    right: unset;
    margin: 2%;
    z-index: 121;
  }
}

.page-title{
  text-align: center;
  color: #D1B986;
  color: var(--accent-gold-color);
}

@media only screen and (max-width: 500px) {
  .page-title{
    font-size: 1.5em;

    margin-top:10%;
  }
  
}

.p-0{
  padding: 0 !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.btn-circle {
  width: 40px;
  height: 40px;
  padding: 4px 10px;
  border-radius: 35px;
  font-size: 25px;
  line-height: 1.33;
  background: white;
  background: var(--main-light-color);
  color: #4A4A4A;
  color: var(--accent-mid-color);
  border: none;
  cursor: pointer;
  margin: 10px;
}
.landing #MainHomeLogo{
  width: 40%;
  min-width: 400px;
  opacity: 1;
}

.landing .background-image-cover{
  background-image: url("/static/media/building 1.448e187e.jpg");
  background-size: cover;
  opacity: .08;

}

.landing #VideoContainer{
  transition: 0.5s;

  z-index: 100000000000000000000000;
  position: fixed;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: black;
}


.landing #VideoContainer.hidden{

  height: 15vh;
  width: 45vh;

  opacity: 0;
  visibility: hidden;

}

@media only screen and (max-width: 500px) {
  .landing #VideoContainer.hidden{

    height: 0;
    width: 100vw;
  
  
  }
}

.landing #VideoContainer #closeBtn{
  position: absolute;
  right: 0;
  z-index: 10000000;
}
.landing .video-player{
  height: 100% !important;
  width: 100% !important;
}

.landing .video-player iframe{
  height: 100% !important;
  width: 100% !important;
}

.landing #videoButton{
  position: fixed;
  width: 45vh;
  height: 15vh;
  right: 0;
  bottom: 0;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  cursor: pointer;
  opacity: .5;
}

.landing #videoButton .thumbnail{
  position: absolute;
  width: 15vh;
  height: 15vh;
  left: 0;
  background-image: url("/static/media/video thumbnail.d75e2dc5.png");
  background-size: cover;

}

.landing #videoButton .vid-desc{
  position: absolute;
  width: 30vh;
  height: 15vh;
  left: 15vh;
  top: 0;
  background: black;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media only screen and (max-width: 500px) {
  
  .landing #videoButton{
    width: 100vw;
    height: 33vw;
  }
  
  .landing #videoButton .thumbnail{

    width: 33vw;
    height: 33vw;
  
  }
  
  .landing #videoButton .vid-desc{

    width: 67vw;
    height: 33vw;
    left: unset;
    right: 0;
  }
   
}



.press .card{
  border-radius: 0px;
  background: var(--main-dark-color)
}

.press a{
  transition: 0.5s;
  color: var(--accent-gold-color);
  
}

.press a:hover{
  color: var(--main-white-color);
  text-decoration: none;
}

.press .square-container{
  position:relative;
  overflow:hidden;
  padding-bottom:100%;
  height: 100%;
}



.press .square-container .square-image{
  position:absolute;
  height: 100%;

}

.press .square-half{
  position:relative;
  overflow:hidden;
  padding-bottom:50%;
  object-fit: cover;
}

.press .square-half .square-image{
  position:absolute;
  width:100%;
}

@media only screen and (max-width: 992px) {
  .press .square-container{
    position:relative;
    overflow:hidden;
    padding-bottom:50%;
    object-fit: cover;
  }

  .press .square-container .square-image{
    position:absolute;
    height: auto;
    width: 100%;
  }
  
}


@media only screen and (max-width: 992px) {
  .csr h2{
    font-size: 1.5rem;
    line-height: 30px;
  }
}


.csr p{
  line-height: 40px;
}

@media only screen and (max-width: 992px) {
  .csr p{
    line-height: 20px;
  }
}

.csr .float-top{
  top:0;
}

.csr .float-bottom{
  bottom: 0;
}

.csr #downbtn{
  z-index: 10;
}

.csr #scroll-part{
z-index: 0;
}
@media only screen and (max-width: 992px) {
  .csr #scroll-part{
    z-index: 0;

    margin-top: 20%;
    margin-bottom: 20%;
    }
}


.csr #clickable-scroll{
  z-index: 100;

}



.csr #scroll-btn{
  width: 100px;
  text-align: center;
  height: 50px;
  font: 3em sans-serif;
  color: var(--accent-mid-color);
  -webkit-animation: float 2.5s ease-in-out infinite;
          animation: float 2.5s ease-in-out infinite;
}

@-webkit-keyframes float {
	0% {
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
        
	}
	50% {
		-webkit-transform: translatey(-20px);
		        transform: translatey(-20px);
        
	}
	100% {
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}

@keyframes float {
	0% {
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
        
	}
	50% {
		-webkit-transform: translatey(-20px);
		        transform: translatey(-20px);
        
	}
	100% {
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}

.plant-bg {
  z-index: -1;
}

.plant-bg .soil{
  

  background: var(--accent-dark-color);
}

.plant-container{
  width: 40%;
  bottom:13%;
  left: 60%;


}

.plant{
  transition: 0.5s;
  opacity: 0;
  visibility: hidden; 
}
#plant1.plant.show-1,#plant2.plant.show-2,#plant3.plant.show-3{
  opacity: 1; 
  visibility: visible; 
}


.plant path{
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .plant-container{
    width: 90%;
    bottom:13%;
    left:5%;
  }
}
.background-container.projects{
  
}

.projects #titles{
  z-index: 10;
  position: fixed;
  
}

@media only screen and (max-width: 992px) {
  
  
  .projects #titles{
    z-index: 10;
    position: relative;
  }
}

.projects .no-select{
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}



.projects #contents{
  z-index: 1;
  position: fixed;
  
}

@media only screen and (max-width: 992px) {
  .projects #contents{
    z-index: 1;
    position: relative;
    
  }
}



.projects .container-fluid{
  padding: 0;
}

.projects #PH-Map-Container{
  background: var(--accent-darker-color);
  overflow: hidden;
  position: relative;
  padding: 0;
  height: 100vh;
}


@media only screen and (max-width: 992px) {
  .projects #PH-Map-Container{
    background: none;
    height: 80vh;
  }
}


.projects .PH-Map-Interact{
  height: 110vh;
  width: 110vh;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9920px;

  margin: auto;
  position: absolute;
}

.projects #PH-Map{
  height: 110vh;

  width: 110vh;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  z-index: 1;
}
@media only screen and (max-width: 992px) {
  .projects .PH-Map-Interact{
    height: 90vh;
    width: 90vh;
    top: -9999px;
  }
  
  .projects #PH-Map{
    height: 90vh;
  
    width: 90vh ;
  }
}

.projects .map-circle{
  width: 2vh;
  height: 2vh;
  border-radius: 50%;
  border: white 2px solid;
  position: absolute;

  z-index: 1000000;
   
} 

@media only screen and (max-width: 992px) {
.projects #Table-Main-Container{
  
  background: black;
}
}



.projects #Table-Container{
  
  height: 40%;
  top: 30%;
  position: relative;
  padding-left: 10%;
  padding-right: 10%;

  overflow-y: scroll;
}

@media only screen and (max-width: 992px) {
  
  .projects #Table-Container{
    top: 0%;
    margin: 10% 0%;
    overflow-y: visible;
  }
}

.projects #Table-Container-2{
  width: 100%;
  display: block;
}

.projects thead{

}

@media only screen and (max-width: 992px) {
  
  .projects thead{
    display: none;
  }
}

.projects .TableItemMobile{
  border-top: 1px solid white
}

.projects .TableItemMobile .accordion-table{
  font-size: .6em;
}

.projects .TableItemMobile .accordion-table .subtext{
  padding-top: 10px;
  color: gray;
}
.aboutus .background-img{
  height: 100%;
  width: 100%;
  background-image: url(/static/media/buildings_bw.ec9f4888.jpg);
  background-size: cover;
  opacity: .08;
  position: fixed;
  top: 0;
  left:0;
  z-index: -1;
}

.aboutus #Menu{
  margin-top: 10%;
  padding-left:5%;
  padding-right:5%;
  transition: 0.5s;
  opacity: 1;
}
.aboutus #Menu.hide{
  opacity: 0;
  visibility: hidden;
}

.aboutus .h-line{
  position: relative;
  border-bottom: #808080 1px solid;
  height: 15%;
  width: 50%;
}



.aboutus .h-line{
  position: relative;
  border-bottom: #808080 1px solid;
  height: 15%;
  width: 50%;
}

.aboutus .page-title{
  transition: 0.5s;

  opacity: 1;
}

.aboutus .page-title.faded{

  opacity: 0;
}

.aboutus #Content-Container{
  transition: 0.5s;

  position: fixed;
  background: var(--main-mid-color);
  width: 97%;
  height: 65%;
  bottom: 0;
  z-index: 1000;
  
}

.aboutus .overflow-y-content{
  overflow-y: auto;  
}

.aboutus .overflow-x-content{
  overflow-x: scroll;  
  overflow-y: hidden;  
}

.aboutus #Content-Container.hide{

  height: 0%;
  
}

@media only screen and (max-width: 992px) {
  .aboutus #Content-Container{
    width: 100%;
  }
}

.aboutus #back-btn{
  right: 3%;
  top: 85%;
  position: absolute;
}

.aboutus .profile img{
  height: 150px;
  border-radius: 50%;
  position: relative;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}



.aboutus #timeline{
  height:   100%;
}



.aboutus #timeline .h-line-l{
  position: absolute;
  border-bottom: #ADADAD 2px solid;
  height: 12px;
  width: 50%;
  right:50%;
  z-index: 2;
}

.aboutus #timeline .h-line-r{
  position: absolute;
  border-bottom: #ADADAD 2px solid;
  height: 12px;
  width: 50%;
  left:50%;
  z-index: 2;
}

.aboutus #timeline .circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ADADAD;
  z-index: 10;
}

.aboutus #timeline .linePart{
  width: 100%;
  height: 15px;
}
.contact h2{
    font-size: 36px;
    font-weight: 400;
}

.contact .form-control{
    background: linear-gradient(0deg, #ADADAD 2px,rgba(0,150,136,0) 0),linear-gradient(0deg,rgba(0,0,0,.26) 1px,transparent 0) !important;
}

.contact .form-group{
    color: #808080 !important;
}


@media only screen and (max-width: 992px) {
    .contact #Main-Content{
        padding:0 !important;
        padding-top:5% !important;
    }

    .contact #Row-Content{
        padding:0 !important;
    }

    
}


.contact #ContactInfoMobile{
    display: none;
}

@media only screen and (max-width: 992px) {
    .contact #ContactInfoMobile{
        display: unset;
        background: black;
    }

    .contact #ContactInfo{
        display: none;
    }
}
.companies #titles{
  z-index: 10
}

.companies #contents{
  z-index: 1
}


.companies .container-fluid{
  padding: 0;
}


.companies #SVG-Container{
  overflow: hidden;
  position: relative;
  padding: 0;
  height: 100%;
}

.companies .SVG-Interact{
  height: 210vh;
  width: 210vh;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;

  margin: auto;
  position: absolute;
}

.companies #SVG-Circles{
  height: 210vh;

  width: 210vh;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  z-index: 1;
}

@media only screen and (max-width: 992px) {
  .companies .SVG-Interact{
    height: 170vh;
    width: 170vh;
    top: -9900px;
    bottom: -9999px;
    left: -9750px;
    right: -9999px;
  

  }

  .companies #SVG-Circles{
    height: 170vh;
    width: 170vh;

  }
}

 .companies .svg-click{
  width: 2vh;
  height: 2vh;
  border-radius: 50%;
  border: var(--accent-mid-color) 1px solid;
  position: absolute; 

  z-index: 100;
  transition: 0.5s;
   
} 

.companies .light .svg-click{
  border: none;
  background-color: var(--accent-gold-color);
}

.companies .svg-text{
  color:var(--accent-mid-color);
  position: absolute;
  z-index: 100;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
  font-size: 2vh;
  font-weight: 500;
  transition: 0.5s;
}

@media only screen and (max-width: 992px) {
  .companies .svg-text{

    font-size: 1.4vh;
    font-weight: 600;

  }
}

.companies .light .svg-text{
  color:var(--accent-gold-color);
  
}

.companies #MainText{
  width: 43vh;
  height: 43vh;
  position: absolute;
  /* border-radius: 50%;
  border: white 2px solid; */
  left: 39%;
  top: 42%;
  z-index: 100;
  text-align: center;
   
}


@media only screen and (max-width: 992px) {
  .companies #MainText{
    width: 25vh;
    height: 25vh;
    position: absolute;
    /* border-radius: 50%;
    border: white 2px solid; */
    left: 41%;
    top: 46%;
    z-index: 100;
    text-align: center;
     
  }
}

.companies #MainText.hidden{
  visibility: hidden;
  opacity: 0;
   
}

.companies #MainText h4{
  font-size: 2.5vh;
   
}

@media only screen and (max-width: 992px) {
  
    .companies #MainText h4{
      font-size: 1.8vh;
       
    }
     
}


.article p{
  margin: 3rem!important;
  line-height: 43px;
  font-size: 20px;
}

.article h2{
  margin-top: 5rem!important;
  margin-bottom: 5rem!important;
}

.Nav-Stuff #NavMenu{
  background: var(--main-dark-color);
  color: var(--main-light-color);
  z-index: 1;
}

.Nav-Stuff #NavContainer{
  transition: 0.5s;
  opacity: 0;
  visibility: hidden; 
}


.Nav-Stuff #NavContainer.show {
  opacity: 1; 
  visibility: visible; 
}

.Nav-Stuff #Nav-Hamburger{
  background: var(--main-dark-color);
  width: 3%;
  z-index: 2;
  cursor: pointer;
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .Nav-Stuff #Nav-Hamburger{
    height: 5% !important;
    width: 10%;
    background: none;
    right: 10%;
    top: 2%
    
  }

  .Nav-Stuff .vertical-center-2{
    width: 10%;
    float: right;

    margin: 3%;
  }


}

.Nav-Stuff .a{
  transition: 0.5s;
  color: var(--main-light-color);
  
}

.Nav-Stuff a:hover{
  color: var(--accent-gold-color);
}






.Nav-Stuff #NavMenu-Inner{
  margin-left: 20%;
  margin-right: 20%;
  z-index:10;
}

@media only screen and (max-width: 992px) {
  .Nav-Stuff #NavMenu-Inner{
    margin-left: 10%;
    margin-right: 10%;
  }
}

.Nav-Stuff #BackArrow{
  height: 150%;
  top:-10%;
}


.Nav-Stuff #HomeLogo{
  width: 25%;
  right: 0;
  top:0;
  float: right;
  margin: 2%;
  z-index: 121;
}

@media only screen and (max-width: 992px) {
  .Nav-Stuff #HomeLogo{
    width: 35%;
    float: left;
    top:0;
    left:0;
    right: unset;
    margin: 2%;
    z-index: 121;
  }
}

@media only screen and (max-width: 992px) {
  .Nav-Stuff h1{
    font-size: 1rem
  }
}
