.aboutus .background-img{
  height: 100%;
  width: 100%;
  background-image: url('../../Assets/photos/buildings_bw.jpg');
  background-size: cover;
  opacity: .08;
  position: fixed;
  top: 0;
  left:0;
  z-index: -1;
}

.aboutus #Menu{
  margin-top: 10%;
  padding-left:5%;
  padding-right:5%;

  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 1;
}
.aboutus #Menu.hide{
  opacity: 0;
  visibility: hidden;
}

.aboutus .h-line{
  position: relative;
  border-bottom: #808080 1px solid;
  height: 15%;
  width: 50%;
}



.aboutus .h-line{
  position: relative;
  border-bottom: #808080 1px solid;
  height: 15%;
  width: 50%;
}

.aboutus .page-title{
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;

  opacity: 1;
}

.aboutus .page-title.faded{

  opacity: 0;
}

.aboutus #Content-Container{
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;

  position: fixed;
  background: var(--main-mid-color);
  width: 97%;
  height: 65%;
  bottom: 0;
  z-index: 1000;
  
}

.aboutus .overflow-y-content{
  overflow-y: auto;  
}

.aboutus .overflow-x-content{
  overflow-x: scroll;  
  overflow-y: hidden;  
}

.aboutus #Content-Container.hide{

  height: 0%;
  
}

@media only screen and (max-width: 992px) {
  .aboutus #Content-Container{
    width: 100%;
  }
}

.aboutus #back-btn{
  right: 3%;
  top: 85%;
  position: absolute;
}

.aboutus .profile img{
  height: 150px;
  border-radius: 50%;
  position: relative;
  filter: grayscale(100%);
}



.aboutus #timeline{
  height:   100%;
}



.aboutus #timeline .h-line-l{
  position: absolute;
  border-bottom: #ADADAD 2px solid;
  height: 12px;
  width: 50%;
  right:50%;
  z-index: 2;
}

.aboutus #timeline .h-line-r{
  position: absolute;
  border-bottom: #ADADAD 2px solid;
  height: 12px;
  width: 50%;
  left:50%;
  z-index: 2;
}

.aboutus #timeline .circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ADADAD;
  z-index: 10;
}

.aboutus #timeline .linePart{
  width: 100%;
  height: 15px;
}