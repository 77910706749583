

@media only screen and (max-width: 992px) {
  .csr h2{
    font-size: 1.5rem;
    line-height: 30px;
  }
}


.csr p{
  line-height: 40px;
}

@media only screen and (max-width: 992px) {
  .csr p{
    line-height: 20px;
  }
}

.csr .float-top{
  top:0;
}

.csr .float-bottom{
  bottom: 0;
}

.csr #downbtn{
  z-index: 10;
}

.csr #scroll-part{
z-index: 0;
}
@media only screen and (max-width: 992px) {
  .csr #scroll-part{
    z-index: 0;

    margin-top: 20%;
    margin-bottom: 20%;
    }
}


.csr #clickable-scroll{
  z-index: 100;

}



.csr #scroll-btn{
  width: 100px;
  text-align: center;
  height: 50px;
  font: 3em sans-serif;
  color: var(--accent-mid-color);
  animation: float 2.5s ease-in-out infinite;
}

@keyframes float {
	0% {
		transform: translatey(0px);
        
	}
	50% {
		transform: translatey(-20px);
        
	}
	100% {
		transform: translatey(0px);
	}
}

.plant-bg {
  z-index: -1;
}

.plant-bg .soil{
  

  background: var(--accent-dark-color);
}

.plant-container{
  width: 40%;
  bottom:13%;
  left: 60%;


}

.plant{
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden; 
}
#plant1.plant.show-1,#plant2.plant.show-2,#plant3.plant.show-3{
  opacity: 1; 
  visibility: visible; 
}


.plant path{
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .plant-container{
    width: 90%;
    bottom:13%;
    left:5%;
  }
}