.companies #titles{
  z-index: 10
}

.companies #contents{
  z-index: 1
}


.companies .container-fluid{
  padding: 0;
}


.companies #SVG-Container{
  overflow: hidden;
  position: relative;
  padding: 0;
  height: 100%;
}

.companies .SVG-Interact{
  height: 210vh;
  width: 210vh;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;

  margin: auto;
  position: absolute;
}

.companies #SVG-Circles{
  height: 210vh;

  width: 210vh;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  z-index: 1;
}

@media only screen and (max-width: 992px) {
  .companies .SVG-Interact{
    height: 170vh;
    width: 170vh;
    top: -9900px;
    bottom: -9999px;
    left: -9750px;
    right: -9999px;
  

  }

  .companies #SVG-Circles{
    height: 170vh;
    width: 170vh;

  }
}

 .companies .svg-click{
  width: 2vh;
  height: 2vh;
  border-radius: 50%;
  border: var(--accent-mid-color) 1px solid;
  position: absolute; 

  z-index: 100;

  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
   
} 

.companies .light .svg-click{
  border: none;
  background-color: var(--accent-gold-color);
}

.companies .svg-text{
  color:var(--accent-mid-color);
  position: absolute;
  z-index: 100;
  width: max-content;
  text-align: center;
  font-size: 2vh;
  font-weight: 500;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@media only screen and (max-width: 992px) {
  .companies .svg-text{

    font-size: 1.4vh;
    font-weight: 600;

  }
}

.companies .light .svg-text{
  color:var(--accent-gold-color);
  
}

.companies #MainText{
  width: 43vh;
  height: 43vh;
  position: absolute;
  /* border-radius: 50%;
  border: white 2px solid; */
  left: 39%;
  top: 42%;
  z-index: 100;
  text-align: center;
   
}


@media only screen and (max-width: 992px) {
  .companies #MainText{
    width: 25vh;
    height: 25vh;
    position: absolute;
    /* border-radius: 50%;
    border: white 2px solid; */
    left: 41%;
    top: 46%;
    z-index: 100;
    text-align: center;
     
  }
}

.companies #MainText.hidden{
  visibility: hidden;
  opacity: 0;
   
}

.companies #MainText h4{
  font-size: 2.5vh;
   
}

@media only screen and (max-width: 992px) {
  
    .companies #MainText h4{
      font-size: 1.8vh;
       
    }
     
}

