.contact h2{
    font-size: 36px;
    font-weight: 400;
}

.contact .form-control{
    background: linear-gradient(0deg, #ADADAD 2px,rgba(0,150,136,0) 0),linear-gradient(0deg,rgba(0,0,0,.26) 1px,transparent 0) !important;
}

.contact .form-group{
    color: #808080 !important;
}


@media only screen and (max-width: 992px) {
    .contact #Main-Content{
        padding:0 !important;
        padding-top:5% !important;
    }

    .contact #Row-Content{
        padding:0 !important;
    }

    
}


.contact #ContactInfoMobile{
    display: none;
}

@media only screen and (max-width: 992px) {
    .contact #ContactInfoMobile{
        display: unset;
        background: black;
    }

    .contact #ContactInfo{
        display: none;
    }
}