:root {
  --main-dark-color: black; 
  --main-mid-color: #292929;
  --main-light-color: white;
  --accent-gold-color: #D1B986;
  --accent-darker-color:#1C1C1C;
  --accent-dark-color: #363636;
  --accent-mid-color: #4A4A4A;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.4; 
  color: var(--main-light-color);

  background: var(--main-mid-color);
}

.NavigationBar{
  z-index: 1000000;
}

a{
  
  color: var(--main-light-color);
}

.main-content{
  margin-left: 3%;
}

@media only screen and (max-width: 992px) {
  .main-content{
    margin-left: 0%;
  }
}

h1{
  letter-spacing: 0px;
  font-weight: 400;
  text-transform: uppercase;
}

h2{
  letter-spacing: 0px;
  font-weight: 300;
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.vertical-center-2 {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  display: flex;
  align-items: center;
}

.vertical-center-3 {
  display: flex;
  align-items: center;
}

.background-container{
  width: 100%;
  height: 100%;

}

.text-gold{
  color:var(--accent-gold-color);
}

.row{
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
  
}

.card-image{
  overflow: hidden;
}


.cbg-dark{
  background-color: var(--main-dark-color);

  background-clip: content-box;
}

#HomeLogoContainer{
  margin-left: 75%;
}

#HomeLogo{
  width: 100%;
  right: 0;
  top:0;
  float: right;
  margin: 2%;
  z-index: 121;
  display: inline-block;
}

@media only screen and (max-width: 992px) {
  #HomeLogoContainer{
    margin-right: 60%;
    margin-left: 0;
  }
  
  #HomeLogo{
    width: 100%;
    float: left;
    top:0;
    
    right: unset;
    margin: 2%;
    z-index: 121;
  }
}

.page-title{
  text-align: center;
  color: var(--accent-gold-color);
}

@media only screen and (max-width: 500px) {
  .page-title{
    font-size: 1.5em;

    margin-top:10%;
  }
  
}

.p-0{
  padding: 0 !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.btn-circle {
  width: 40px;
  height: 40px;
  padding: 4px 10px;
  border-radius: 35px;
  font-size: 25px;
  line-height: 1.33;
  background: var(--main-light-color);
  color: var(--accent-mid-color);
  border: none;
  cursor: pointer;
  margin: 10px;
}