


.press .card{
  border-radius: 0px;
  background: var(--main-dark-color)
}

.press a{
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: var(--accent-gold-color);
  
}

.press a:hover{
  color: var(--main-white-color);
  text-decoration: none;
}

.press .square-container{
  position:relative;
  overflow:hidden;
  padding-bottom:100%;
  height: 100%;
}



.press .square-container .square-image{
  position:absolute;
  height: 100%;

}

.press .square-half{
  position:relative;
  overflow:hidden;
  padding-bottom:50%;
  object-fit: cover;
}

.press .square-half .square-image{
  position:absolute;
  width:100%;
}

@media only screen and (max-width: 992px) {
  .press .square-container{
    position:relative;
    overflow:hidden;
    padding-bottom:50%;
    object-fit: cover;
  }

  .press .square-container .square-image{
    position:absolute;
    height: auto;
    width: 100%;
  }
  
}