
.Nav-Stuff #NavMenu{
  background: var(--main-dark-color);
  color: var(--main-light-color);
  z-index: 1;
}

.Nav-Stuff #NavContainer{
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden; 
}


.Nav-Stuff #NavContainer.show {
  opacity: 1; 
  visibility: visible; 
}

.Nav-Stuff #Nav-Hamburger{
  background: var(--main-dark-color);
  width: 3%;
  z-index: 2;
  cursor: pointer;
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .Nav-Stuff #Nav-Hamburger{
    height: 5% !important;
    width: 10%;
    background: none;
    right: 10%;
    top: 2%
    
  }

  .Nav-Stuff .vertical-center-2{
    width: 10%;
    float: right;

    margin: 3%;
  }


}

.Nav-Stuff .a{
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: var(--main-light-color);
  
}

.Nav-Stuff a:hover{
  color: var(--accent-gold-color);
}






.Nav-Stuff #NavMenu-Inner{
  margin-left: 20%;
  margin-right: 20%;
  z-index:10;
}

@media only screen and (max-width: 992px) {
  .Nav-Stuff #NavMenu-Inner{
    margin-left: 10%;
    margin-right: 10%;
  }
}

.Nav-Stuff #BackArrow{
  height: 150%;
  top:-10%;
}


.Nav-Stuff #HomeLogo{
  width: 25%;
  right: 0;
  top:0;
  float: right;
  margin: 2%;
  z-index: 121;
}

@media only screen and (max-width: 992px) {
  .Nav-Stuff #HomeLogo{
    width: 35%;
    float: left;
    top:0;
    left:0;
    right: unset;
    margin: 2%;
    z-index: 121;
  }
}

@media only screen and (max-width: 992px) {
  .Nav-Stuff h1{
    font-size: 1rem
  }
}