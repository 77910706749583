.background-container.projects{
  
}

.projects #titles{
  z-index: 10;
  position: fixed;
  
}

@media only screen and (max-width: 992px) {
  
  
  .projects #titles{
    z-index: 10;
    position: relative;
  }
}

.projects .no-select{
  user-select: none;
  pointer-events: none;
}



.projects #contents{
  z-index: 1;
  position: fixed;
  
}

@media only screen and (max-width: 992px) {
  .projects #contents{
    z-index: 1;
    position: relative;
    
  }
}



.projects .container-fluid{
  padding: 0;
}

.projects #PH-Map-Container{
  background: var(--accent-darker-color);
  overflow: hidden;
  position: relative;
  padding: 0;
  height: 100vh;
}


@media only screen and (max-width: 992px) {
  .projects #PH-Map-Container{
    background: none;
    height: 80vh;
  }
}


.projects .PH-Map-Interact{
  height: 110vh;
  width: 110vh;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9920px;

  margin: auto;
  position: absolute;
}

.projects #PH-Map{
  height: 110vh;

  width: 110vh;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  z-index: 1;
}
@media only screen and (max-width: 992px) {
  .projects .PH-Map-Interact{
    height: 90vh;
    width: 90vh;
    top: -9999px;
  }
  
  .projects #PH-Map{
    height: 90vh;
  
    width: 90vh ;
  }
}

.projects .map-circle{
  width: 2vh;
  height: 2vh;
  border-radius: 50%;
  border: white 2px solid;
  position: absolute;

  z-index: 1000000;
   
} 

@media only screen and (max-width: 992px) {
.projects #Table-Main-Container{
  
  background: black;
}
}



.projects #Table-Container{
  
  height: 40%;
  top: 30%;
  position: relative;
  padding-left: 10%;
  padding-right: 10%;

  overflow-y: scroll;
}

@media only screen and (max-width: 992px) {
  
  .projects #Table-Container{
    top: 0%;
    margin: 10% 0%;
    overflow-y: visible;
  }
}

.projects #Table-Container-2{
  width: 100%;
  display: block;
}

.projects thead{

}

@media only screen and (max-width: 992px) {
  
  .projects thead{
    display: none;
  }
}

.projects .TableItemMobile{
  border-top: 1px solid white
}

.projects .TableItemMobile .accordion-table{
  font-size: .6em;
}

.projects .TableItemMobile .accordion-table .subtext{
  padding-top: 10px;
  color: gray;
}