.landing #MainHomeLogo{
  width: 40%;
  min-width: 400px;
  opacity: 1;
}

.landing .background-image-cover{
  background-image: url('../../Assets/photos/building 1.jpg');
  background-size: cover;
  opacity: .08;

}

.landing #VideoContainer{
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;

  z-index: 100000000000000000000000;
  position: fixed;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: black;
}


.landing #VideoContainer.hidden{

  height: 15vh;
  width: 45vh;

  opacity: 0;
  visibility: hidden;

}

@media only screen and (max-width: 500px) {
  .landing #VideoContainer.hidden{

    height: 0;
    width: 100vw;
  
  
  }
}

.landing #VideoContainer #closeBtn{
  position: absolute;
  right: 0;
  z-index: 10000000;
}
.landing .video-player{
  height: 100% !important;
  width: 100% !important;
}

.landing .video-player iframe{
  height: 100% !important;
  width: 100% !important;
}

.landing #videoButton{
  position: fixed;
  width: 45vh;
  height: 15vh;
  right: 0;
  bottom: 0;
  flex-wrap: nowrap;
  cursor: pointer;
  opacity: .5;
}

.landing #videoButton .thumbnail{
  position: absolute;
  width: 15vh;
  height: 15vh;
  left: 0;
  background-image: url('../../Assets/photos/video thumbnail.png');
  background-size: cover;

}

.landing #videoButton .vid-desc{
  position: absolute;
  width: 30vh;
  height: 15vh;
  left: 15vh;
  top: 0;
  background: black;
  user-select: none;
}

@media only screen and (max-width: 500px) {
  
  .landing #videoButton{
    width: 100vw;
    height: 33vw;
  }
  
  .landing #videoButton .thumbnail{

    width: 33vw;
    height: 33vw;
  
  }
  
  .landing #videoButton .vid-desc{

    width: 67vw;
    height: 33vw;
    left: unset;
    right: 0;
  }
   
}